@charset "UTF-8";
/*ブレイクポイント*/
/* フォント*/
/* カラー */
.hero {
  position: relative;
}
.hero__image {
  width: 100vw;
}
.hero__main {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 959px) {
  .hero__main {
    width: 100%;
  }
}
.hero__title-image {
  margin: auto;
}
@media screen and (max-width: 959px) {
  .hero__title-image {
    max-width: 40.5rem;
  }
}
.hero__text {
  font-size: 3.6rem;
  line-height: 5.2rem;
  letter-spacing: 0.018em;
  color: #fff;
  text-align: center;
  margin-top: 1.8rem;
}

.news-box {
  height: 220px;
  overflow-y: scroll;
  border: solid 1px #F2F2F2;
  padding: 0 4.5rem;
  margin-bottom: 10rem;
  background: #fff;
}
@media screen and (max-width: 959px) {
  .news-box {
    height: 26rem;
    padding: 0 4rem;
    margin: 0 0 10rem;
  }
}
.news-box__list {
  display: flex;
  padding: 2.5rem 0;
  border-bottom: 1px solid #D5D5D5;
}
.news-box__list:last-child {
  border: 0;
}
.news-box__date {
  padding-right: 4rem;
  color: #0069B1;
}
@media screen and (max-width: 959px) {
  .news-box__date {
    padding-right: 1rem;
  }
}

.event-box__item-figure img {
  margin: auto;
  width: 100%;
}
.event-box__item-date {
  color: #0069B1;
  margin-top: 1.6rem;
  margin-bottom: 0.7rem;
}
.event-box__item-title {
  font-weight: bold;
}
@media screen and (max-width: 959px) {
  .event-box__item-title {
    font-weight: normal;
  }
}
.event-box__more-btn {
  text-align: center;
}

.eventreport-box__more-btn {
  text-align: center;
}
@media screen and (max-width: 959px) {
  .eventreport-box__item-figure img {
    width: 100%;
  }
}
.eventreport-box__item-date {
  margin: 1.5rem 0 0.7rem;
  color: #0069B1;
}
.content--1 {
  margin-bottom: 16.5rem;
}
@media screen and (max-width: 959px) {
  .content--1 {
    margin-bottom: 0;
  }
}

.content--2 {
  background-color: #0069B1;
  position: relative;
  height: 43.5rem;
}
@media screen and (max-width: 959px) {
  .content--2 {
    height: auto;
  }
  .content--2 .content__inner {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }
}

.content--4 {
  background-color: #EFFCFF;
}

.content--5 .content__inner {
  padding: 5rem 0;
}
@media screen and (max-width: 959px) {
  .content--5 .content__inner {
    padding: 7rem 4rem;
  }
}

.top-catch {
  display: flex;
}
@media screen and (max-width: 959px) {
  .top-catch {
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }
}
.top-catch__left {
  width: 100%;
  max-width: 33.3rem;
  color: #fff;
}
@media screen and (max-width: 959px) {
  .top-catch__left {
    max-width: 100%;
    flex: 0 0 100%;
    padding: 4rem 4rem 0 4rem;
  }
}
.top-catch__title {
  font-size: 3rem;
  margin-bottom: 2.7rem;
}
@media screen and (max-width: 959px) {
  .top-catch__title {
    font-size: 4rem;
    font-weight: bold;
    line-height: 5rem;
  }
}
.top-catch__text {
  line-height: 2.5rem;
}
@media screen and (max-width: 959px) {
  .top-catch__text {
    line-height: 4.5rem;
  }
}
.top-catch__right {
  position: absolute;
  left: 50%;
  top: -4rem;
}
@media screen and (max-width: 959px) {
  .top-catch__right {
    flex: 0 0 100%;
    position: static;
  }
}

.top-message {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 959px) {
  .top-message {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 959px) {
  .top-message__figure {
    flex: 0 0 27%;
  }
}
.top-message__text {
  padding-left: 5rem;
  padding-right: 5rem;
}
@media screen and (max-width: 959px) {
  .top-message__text {
    flex: 0 0 73%;
  }
}
.top-message__text-main {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
@media screen and (max-width: 959px) {
  .top-message__text-sub {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 959px) {
  .top-message__more-btn {
    margin-top: 4rem;
    flex: 0 0 100%;
    text-align: center;
  }
}

.top-bottom-links__items {
  max-width: 1920px;
  margin: auto;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 959px) {
  .top-bottom-links__items {
    flex-wrap: wrap;
    margin: 0 -2rem;
  }
}
.top-bottom-links__item {
  padding: 0 1.25rem;
}
@media screen and (max-width: 959px) {
  .top-bottom-links__item {
    flex: 0 0 50%;
    padding: 0 2rem;
    margin-bottom: 4rem;
  }
}

.column__items,
.event__lists {
  position: relative;
}

.swiper-button-next,
.swiper-button-prev {
  width: 1.5rem;
  height: 1.5rem;
}
.swiper-button-next::after,
.swiper-button-prev::after {
  content: "";
  width: 1.5rem;
  height: 1.5rem;
  border: 0px;
  border-top: solid 0.5rem #8C8785;
  border-right: solid 0.5rem #8C8785;
}

.swiper-button-next {
  right: -1%;
}
@media screen and (max-width: 959px) {
  .swiper-button-next--event {
    right: 4%;
  }
}
.swiper-button-next::after {
  transform: rotate(45deg);
}

.swiper-button-prev {
  left: -2%;
}
@media screen and (max-width: 959px) {
  .swiper-button-prev--event {
    left: 4%;
  }
}
.swiper-button-prev::after {
  transform: rotate(225deg);
}

.swiper-pagination--event {
  bottom: -13%;
  left: 50%;
  transform: translate(-50%, 0%);
}
@media screen and (max-width: 959px) {
  .swiper-pagination--event {
    bottom: -10%;
  }
}

.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 4px;
}
